import {
  Container,
  CustomSelect,
  FieldContainer,
  FormButtonContainer,
  FormContainer,
  HelperText,
  Input,
  Label,
  MessageInput,
  NavBar,
  Overlay,
  ServicesOptions,
  Title,
} from '../../components/contact-us/contact-us-form-elements';

import Background from '../../img/contact-us-bg.jpeg';
import Checkbox from '../../components/Checkbox';
import Layout from '../../components/Layout';
import { Link } from 'gatsby';
import React from 'react';
import logo from '../../img/logo.svg';
import { navigate } from 'gatsby';

const ContactUsPageOther = () => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [subscribeToNewsletter, setSubscribeToNewsletter] = React.useState(false);
  const [privacyTerms, setPrivacyTerms] = React.useState(false);

  const [formSubmitted, setFormSubmitted] = React.useState(false);

  const encode = (data) => {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  const handleSubmit = (e) => {
    const data = {
      'form-name': 'contact',
      name,
      email,
      phone,
      message,
      subscribeToNewsletter: subscribeToNewsletter ? 'Yes' : 'No',
      privacyTerms: privacyTerms ? 'Yes' : 'No',
    };
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode(data),
    });
    setFormSubmitted(true);
    e.preventDefault();
  };

  return (
    <Layout contactus={true}>
      <Container>
        <img
          src={Background}
          alt=""
          className="hero-background"
          style={{ opacity: 0.6, zIndex: '-1', position: 'fixed' }}
        />
        <NavBar>
          <Link to="/" title="Logo">
            <img src={logo} alt="FilmChain" className="filmchain-icon" />
          </Link>
        </NavBar>
        <Overlay />
        <FormContainer>
          <Title className="h1-gradient">Contact us</Title>
          <Label>
            {'What service are you interested in?'}{' '}
            <CustomSelect
              options={ServicesOptions}
              classNamePrefix="fc-select"
              defaultValue={ServicesOptions[3]}
              onChange={(e) => {
                navigate(`/contact-us/${e.value}`);
              }}
            />
          </Label>
          <form
            name="contact"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <FieldContainer>
              <Label>
                Name
                <Input
                  className="input"
                  type="text"
                  name="name"
                  placeholder="Type your name"
                  autocomplete="off"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Label>
            </FieldContainer>

            <FieldContainer>
              <Label>
                Email
                <Input
                  className="input"
                  type="email"
                  name="email"
                  placeholder="Type your email address"
                  autocomplete="off"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Label>
            </FieldContainer>

            <FieldContainer>
              <Label>
                Phone (optional)
                <Input
                  className="input"
                  type="phone"
                  name="phone"
                  placeholder="Type your phone number"
                  autocomplete="off"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
                <HelperText>Please include the country code</HelperText>
              </Label>
            </FieldContainer>

            <FieldContainer>
              <Label>
                Message
                <MessageInput
                  className="input"
                  autocomplete="off"
                  placeholder="Please let us know how we can help you"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  name="message"
                  required
                />
              </Label>
            </FieldContainer>

            <FieldContainer style={{ marginTop: '28px' }}>
              <Checkbox
                id="subscribeToNewsletter"
                name="subscribeToNewsletter"
                label="I would like to subscribe to the FilmChain newsletter."
                value={subscribeToNewsletter}
                checked={subscribeToNewsletter}
                onChange={({ target }) => setSubscribeToNewsletter(!subscribeToNewsletter)}
              />
            </FieldContainer>

            <FieldContainer>
              <Checkbox
                id="privacyTerms"
                name="privacyTerms"
                label={[
                  'I agree with FilmChain’s ',
                  <a
                    key="privacy-link"
                    href="/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline', color: 'white' }}
                  >
                    Privacy terms
                  </a>,
                ]}
                value={privacyTerms}
                checked={privacyTerms}
                onChange={({ target }) => setPrivacyTerms(!privacyTerms)}
                required
              />
            </FieldContainer>

            <FormButtonContainer>
              <button
                className="send btn inverted"
                disabled={formSubmitted}
                style={{ marginTop: '48px', display: 'block', margin: 0 }}
                type="submit"
                ref={(el) => {
                  if (el && formSubmitted) {
                    el.style.setProperty('background-color', '#3ccf74', 'important');
                    el.style.setProperty('background-image', 'none', 'important');
                    el.style.setProperty('pointer-events', 'none', 'important');
                  }
                }}
              >
                {formSubmitted ? 'Sent' : 'Send'}
              </button>
              {formSubmitted && <p>Thank you! We’ll get back to you shortly!</p>}
            </FormButtonContainer>
          </form>
        </FormContainer>
      </Container>
    </Layout>
  );
};

export default ContactUsPageOther;
